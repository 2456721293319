<template>
  <section class="card px-15">
    <h1 class="card-title">
      {{ translate('process_order_error', 'There was an error, we’re unable to process your order') }}
    </h1>
    <div class="d-flex justify-center card-image">
      <v-img :src="images[0].image" :max-width="images[0].width" />
    </div>
  </section>
</template>
<script>
import translationMixin from "../../mixins/translations";

export default {
  name: 'ErrorMessage',
  mixins: [translationMixin],
  computed:{
    images() {
      return [
        { width: '330', image: require('@/assets/img/menu/error.svg') },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  &-title {
    margin: 150px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 128%;
    text-align: center;
    color: #1d1d1d;
  }
}
</style>
