<template>
  <section class="card px-15">
    <svg width="484" height="484" viewBox="0 0 484 484" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="242" cy="241.651" r="241.651" :fill="$vuetify.theme.themes.light.primary"/>
      <rect x="144.925" y="207.81" width="136.615" height="40.5584" rx="20.2792" transform="rotate(45 144.925 207.81)" fill="white"/>
      <rect x="184.93" y="304.411" width="219.347" height="40.5584" rx="20.2792" transform="rotate(-45 184.93 304.411)" fill="white"/>
    </svg>
  </section>
</template>
<script>
export default {
    name:'SuccessMessage'
}
</script>
<style lang="scss" scoped>
.card {
  &-title {
    font-weight: bold;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #2f2f2f;
    margin: 150px 0;
  }
}
</style>
