<template>
  <section class="card px-15">
    <h1 class="card-title">
        {{ translate('pay_counter', 'You can now proceed to the counter to pay for this') }}
    </h1>
    <div class="d-flex justify-center card-image">
      <svg width="484" height="484" viewBox="0 0 484 484" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="242" cy="241.651" r="241.651" :fill="$vuetify.theme.themes.light.primary"/>
        <rect x="144.925" y="207.81" width="136.615" height="40.5584" rx="20.2792" transform="rotate(45 144.925 207.81)" fill="white"/>
        <rect x="184.93" y="304.411" width="219.347" height="40.5584" rx="20.2792" transform="rotate(-45 184.93 304.411)" fill="white"/>
      </svg>
    </div>
  </section>
</template>
<script>
import translationMixin from '../../mixins/translations';

export default {
  name: 'PaymentConfirm',
  mixins: [translationMixin],
};
</script>
<style lang="scss" scoped>
.card {
  &-title {
      margin: 150px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 128%;
    text-align: center;
    color: #1d1d1d;
  }
}
</style>
